import s from './about.module.css';

export default function Tools() {
  return (
    <div className={s.skills}>
      <h3 className={s.skills_title}>Languages and Tools</h3>
      <div className={s.skills_icons}>
        <a
          href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg"
            alt="javascript"
            width="40"
            height="40"
          />
        </a>
        <a
          href="https://www.typescriptlang.org/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg"
            alt="typescript"
            width="40"
            height="40"
          />
        </a>
        <a href="https://nodejs.org" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original-wordmark.svg"
            alt="nodejs"
            width="40"
            height="40"
          />
        </a>
        <a href="https://reactjs.org/" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg"
            alt="react"
            width="40"
            height="40"
          />
        </a>
        <a href="https://redux.js.org" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/devicons/devicon/master/icons/redux/redux-original.svg"
            alt="redux"
            width="40"
            height="40"
          />
        </a>
        <a href="https://nestjs.com/" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/devicons/devicon/master/icons/nestjs/nestjs-plain.svg"
            alt="nestjs"
            width="40"
            height="40"
          />
        </a>
        <a href="https://nextjs.org/" target="_blank" rel="noreferrer">
          <img
            src="https://cdn.worldvectorlogo.com/logos/nextjs-2.svg"
            alt="nextjs"
            width="40"
            height="40"
          />
        </a>
        <a href="https://graphql.org" target="_blank" rel="noreferrer">
          <img
            src="https://www.vectorlogo.zone/logos/graphql/graphql-icon.svg"
            alt="graphql"
            width="40"
            height="40"
          />
        </a>
        <a href="https://www.postgresql.org" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/devicons/devicon/master/icons/postgresql/postgresql-original-wordmark.svg"
            alt="postgresql"
            width="40"
            height="40"
          />
        </a>
        <a href="https://www.mongodb.com/" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original-wordmark.svg"
            alt="mongodb"
            width="40"
            height="40"
          />
        </a>
        <a href="https://www.docker.com/" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original-wordmark.svg"
            alt="docker"
            width="40"
            height="40"
          />
        </a>
        <a href="https://aws.amazon.com" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/devicons/devicon/master/icons/amazonwebservices/amazonwebservices-original-wordmark.svg"
            alt="aws"
            width="40"
            height="40"
          />
        </a>
        <a href="https://getbootstrap.com" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/devicons/devicon/master/icons/bootstrap/bootstrap-plain-wordmark.svg"
            alt="bootstrap"
            width="40"
            height="40"
          />
        </a>
        <a
          href="https://www.w3schools.com/css/"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original-wordmark.svg"
            alt="css3"
            width="40"
            height="40"
          />
        </a>
        <a href="https://expressjs.com" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/devicons/devicon/master/icons/express/express-original-wordmark.svg"
            alt="express"
            width="40"
            height="40"
          />
        </a>
        <a href="https://firebase.google.com/" target="_blank" rel="noreferrer">
          <img
            src="https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg"
            alt="firebase"
            width="40"
            height="40"
          />
        </a>
        <a href="https://git-scm.com/" target="_blank" rel="noreferrer">
          <img
            src="https://www.vectorlogo.zone/logos/git-scm/git-scm-icon.svg"
            alt="git"
            width="40"
            height="40"
          />
        </a>
        <a href="https://www.w3.org/html/" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original-wordmark.svg"
            alt="html5"
            width="40"
            height="40"
          />
        </a>
        <a href="https://jestjs.io" target="_blank" rel="noreferrer">
          <img
            src="https://www.vectorlogo.zone/logos/jestjsio/jestjsio-icon.svg"
            alt="jest"
            width="40"
            height="40"
          />
        </a>
        <a href="https://www.photoshop.com/en" target="_blank" rel="noreferrer">
          <img
            src="https://raw.githubusercontent.com/devicons/devicon/master/icons/photoshop/photoshop-line.svg"
            alt="photoshop"
            width="40"
            height="40"
          />
        </a>
        <a href="https://postman.com" target="_blank" rel="noreferrer">
          <img
            src="https://www.vectorlogo.zone/logos/getpostman/getpostman-icon.svg"
            alt="postman"
            width="40"
            height="40"
          />
        </a>

        <a href="https://reactnative.dev/" target="_blank" rel="noreferrer">
          <img
            src="https://reactnative.dev/img/header_logo.svg"
            alt="reactnative"
            width="40"
            height="40"
          />
        </a>

        <a href="https://tailwindcss.com/" target="_blank" rel="noreferrer">
          <img
            src="https://www.vectorlogo.zone/logos/tailwindcss/tailwindcss-icon.svg"
            alt="tailwind"
            width="40"
            height="40"
          />
        </a>
      </div>
    </div>
  );
}
